
<template>
    <b-container fluid>
        <iq-card>
            <template v-slot:headerTitle>
                <h4 class="card-title">{{ $t('cotton_report.cotton_production_report') }}</h4>
            </template>
            <template v-slot:body>
                <b-row>
                    <b-col xs="12" sm="12" md="6" lg="4" xl="4">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            :label="$t('cotton_config.region')"
                            label-for="region_id"
                            >
                            <b-form-select
                                plain
                                v-model="search.region_id"
                                :options="regionList"
                                id="region_id"
                                :disabled="isOfficeUser"
                                >
                                <template v-slot:first>
                                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-form-group>
                    </b-col>
                    <b-col xs="12" sm="12" md="6" lg="4" xl="4">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            :label="$t('cotton_config.zone')"
                            label-for="zone_id"
                            >
                            <b-form-select
                                plain
                                v-model="search.zone_id"
                                :options="zoneList"
                                id="zone_id"
                                :disabled="isOfficeUser"
                                >
                                <template v-slot:first>
                                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-form-group>
                    </b-col>
                    <b-col xs="12" sm="12" md="6" lg="4" xl="4">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            :label="$t('cotton_config.unit')"
                            label-for="unit_id"
                            >
                            <b-form-select
                                plain
                                v-model="search.unit_id"
                                :options="unitList"
                                id="unit_id"
                                :disabled="isOfficeUser"
                                >
                                <template v-slot:first>
                                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-form-group>
                    </b-col>
                    <b-col xs="12" sm="12" md="6" lg="4" xl="4">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            :label="$t('globalTrans.district')"
                            label-for="district_id"
                            >
                            <b-form-select
                                plain
                                v-model="search.district_id"
                                :options="districtList"
                                id="district_id"
                                :disabled="isOfficeUser"
                                >
                                <template v-slot:first>
                                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-form-group>
                    </b-col>
                    <b-col xs="12" sm="12" md="6" lg="4" xl="4">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            :label="$t('globalTrans.upazila')"
                            label-for="upazila_id"
                            >
                            <b-form-select
                                plain
                                v-model="search.upazila_id"
                                :options="upazilaList"
                                id="upazila_id"
                                :disabled="isOfficeUser"
                                >
                                <template v-slot:first>
                                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-form-group>
                    </b-col>
                    <b-col xs="12" sm="12" md="6" lg="4" xl="4">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            :label="$t('cotton_config.cotton_variety')"
                            label-for="cotton_variety_id"
                            >
                            <b-form-select
                                plain
                                v-model="search.cotton_variety_id"
                                :options="cottonVaritiesList"
                                id="cotton_variety_id"
                                >
                                <template v-slot:first>
                                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-form-group>
                    </b-col>
                    <b-col xs="12" sm="12" md="6" lg="4" xl="4">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            :label="$t('cotton_config.cotton_name')"
                            label-for="cotton_id"
                            >
                            <b-form-select
                                plain
                                v-model="search.cotton_id"
                                :options="cottonNameList"
                                id="cotton_id"
                                >
                                <template v-slot:first>
                                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-form-group>
                    </b-col>
                    <b-col xs="12" sm="12" md="6" lg="4" xl="4">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            :label="$t('cotton_ginner_grower.season')"
                            label-for="season_id"
                            >
                            <b-form-select
                                plain
                                v-model="search.season_id"
                                :options="seasonList"
                                id="season_id"
                                >
                                <template v-slot:first>
                                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-form-group>
                    </b-col>
                    <b-col xs="12" sm="12" md="6" lg="4" xl="4">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            :label="$t('warehouse_config.fiscal_year')"
                            label-for="fiscal_year_id"
                            >
                            <b-form-select
                                plain
                                v-model="search.fiscal_year_id"
                                :options="fiscalList"
                                id="fiscal_year_id"
                                >
                                <template v-slot:first>
                                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" class="text-right">
                        <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search') }}</b-button>
                    </b-col>
                </b-row>
            </template>
        </iq-card>
        <b-row v-show="dataShow">
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{ $t('cotton_report.cotton_production_report') }}</h4>
                    </template>
                    <template v-slot:headerAction>
                            <b-button class="btn_add_new mr-2" @click="pdfExport">
                                <i class="far fa-file-pdf"></i>{{  $t('globalTrans.export_pdf') }}
                            </b-button>
                            <export-excel
                            class="btn btn_add_new"
                            :data="excelData"
                            :title="$t('cotton_report.cotton_production_report')"
                            worksheet="Report Sheet"
                            name="filename.xls">
                                <i class="far fa-file-excel"></i>{{ $t('globalTrans.export_excel') }}
                            </export-excel>
                    </template>
                    <template v-slot:body>
                        <b-overlay :show="loadingState">
                            <b-row>
                              <b-col>
                                <list-report-head :base-url="agriMarketingServiceBaseUrl" :uri="reportHeadingList" :org-id="10">
                                  {{ $t('cotton_report.cotton_production_report') }}
                                </list-report-head>
                              </b-col>
                            </b-row>
                            <b-row>
                                <b-col md="12" class="table-responsive">
                                    <!-- <b-table thead-class="bg-primary" :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                                        <template v-slot:cell(index)="data">
                                            {{ $n(data.index + pagination.slOffset) }}
                                        </template>
                                    </b-table> -->
                                    <b-table-simple :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover aria-hidden="loading | listReload ? 'true' : null">
                                      <thead class="bg-primary">
                                          <tr>
                                            <th class="text-center align-top">{{ $t('globalTrans.sl_no') }}</th>
                                            <th class="text-center align-top">{{ $t('cotton_ginner_grower.growers_name') }}</th>
                                            <th class="text-center align-top">{{ $t('cotton_ginner_grower.region') }}</th>
                                            <th class="text-center align-top">{{ $t('org_pro_district.district') }}</th>
                                            <th class="text-center align-top">{{ $t('org_pro_upazilla.upazilla') }}</th>
                                            <th class="text-center align-top">{{ $t('cotton_ginner_grower.unit_name') }}</th>
                                            <th class="text-center align-top">{{ $t('cotton_ginner_grower.cottonVariety') }}</th>
                                            <th class="text-center align-top">{{ $t('cotton_ginner_grower.cottonName') }}</th>
                                            <th class="text-center align-top">{{ $t('cotton_ginner_grower.quantity') }}</th>
                                          </tr>
                                      </thead>
                                      <tbody>
                                          <tr v-for="(item, index) in cottonProductionList" :key="index">
                                            <td>{{ $n(index + 1) }}</td>
                                            <td>{{ $i18n.locale === 'bn' ? item.name_bn : item.name }}</td>
                                            <td>{{ $i18n.locale === 'bn' ? item.region_name_bn : item.region_name }}</td>
                                            <td>{{ $i18n.locale === 'bn' ? item.district_name_bn : item.district_name }}</td>
                                            <td>{{ $i18n.locale === 'bn' ? item.upazila_name_bn : item.upazila_name }}</td>
                                            <td>{{ $i18n.locale === 'bn' ? item.unit_name_bn : item.unit_name }}</td>
                                            <td>{{ $i18n.locale === 'bn' ? item.cotton_variety_name_bn : item.cotton_variety_name }}</td>
                                            <td>{{ $i18n.locale === 'bn' ? item.cotton_name_bn : item.cotton_name }}</td>
                                            <td class="text-center align-top">{{ $n(item.pro_achi_quantity) }}</td>
                                            <!-- <td class="text-center align-middle" :rowspan="totalRows" v-if="!index">{{ $n(totalQuantity) }}</td> -->
                                          </tr>
                                      </tbody>
                                      <tfoot>
                                        <tr>
                                            <th class="text-right" colspan="8">
                                                {{ $t('cotton_ginner_grower.total_quantity') }}
                                            </th>
                                            <th class="text-center align-middle">{{ $n(totalQuantity) }}</th>
                                        </tr>
                                      </tfoot>
                                    </b-table-simple>
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
      <b-modal id="modal-detail" size="lg" :title="$t('globalTrans.details')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
          <DetailModal :id="editItemId" :key="editItemId" :item="item"/>
      </b-modal>
    </b-container>
</template>
<script>
import DetailModal from './DetailModal'
import RestApi, { agriMarketingServiceBaseUrl } from '@/config/api_config'
import { reportHeadingList, CottonProductionReportList } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import ExportPdf from '@/Utils/export-pdf'
import excel from 'vue-excel-export'
import Vue from 'vue'
import ListReportHead from '@/components/custom/ListReportHead.vue'
Vue.use(excel)

export default {
  mixins: [ModalBaseMasterList],
  components: {
    DetailModal, ListReportHead
  },
  data () {
    return {
      totalRows: 0,
      totalQuantity: 0,
      dataShow: false,
      search: {
        org_id: 10,
        fiscal_year_id: 0,
        region_id: 0,
        zone_id: 0,
        unit_id: 0,
        district_id: 0,
        upazila_id: 0,
        season_id: 0,
        cotton_variety_id: 0,
        cotton_id: 0
      },
      item: '',
      rows: [],
      cottonProductionList: [],
      excelData: [],
      zoneList: [],
      unitList: [],
      upazilaList: [],
      agriMarketingServiceBaseUrl: agriMarketingServiceBaseUrl,
      reportHeadingList: reportHeadingList,
      cottonNameList: []
    }
  },
  created () {
    if (this.isOfficeUser) {
      const authOfficeDetail = this.$store.state.Auth.authUser.office_detail
      const unit = this.$store.state.agriMarketing.commonObj.unitList.find(obj => obj.district_id === authOfficeDetail.district_id)
      if (unit !== undefined) {
        this.search.region_id = unit.region_id
        this.search.zone_id = unit.zone_id
        this.search.unit_id = unit.value
        this.search.district_id = unit.district_id
        this.search.upazila_id = unit.upazilla_id
      }
    }
  },
  mounted () {
  },
  computed: {
    currentLocale () {
        return this.$i18n.locale
    },
    loadingState () {
        if (this.listReload) {
            return true
        } else if (this.loading) {
            return true
        } else {
            return false
        }
    },
    regionList: function () {
        const regionList = this.$store.state.agriMarketing.commonObj.regionList
        return regionList.map(item => {
            if (this.$i18n.locale === 'bn') {
                return { value: item.value, text: item.text_bn }
            } else {
                return { value: item.value, text: item.text_en }
            }
        })
    },
    seasonList: function () {
        const seasonList = this.$store.state.agriMarketing.commonObj.seasonList.filter(item => item.status === 1)
        return seasonList.map(item => {
            if (this.$i18n.locale === 'bn') {
                return { value: item.value, text: item.text_bn }
            } else {
                return { value: item.value, text: item.text_en }
            }
        })
    },
    cottonVaritiesList: function () {
        const ObjectData = this.$store.state.agriMarketing.commonObj.cottonVaritiesList.filter(item => item.status === 1)
        return ObjectData.map(item => {
            if (this.$i18n.locale === 'bn') {
                return { value: item.value, text: item.text_bn }
            } else {
                return { value: item.value, text: item.text_en }
            }
        })
    },
    // cottonNameList: function () {
    //     const ObjectData = this.$store.state.agriMarketing.commonObj.cottonNameList.filter(item => item.status === 1)
    //     return ObjectData.map(item => {
    //         if (this.$i18n.locale === 'bn') {
    //             return { value: item.value, text: item.text_bn }
    //         } else {
    //             return { value: item.value, text: item.text_en }
    //         }
    //     })
    // },
    districtList () {
        return this.$store.state.commonObj.districtList.filter(item => item.status === 0)
    },
    fiscalList: function () {
        const fiscalyearData = this.$store.state.commonObj.fiscalYearList
        return fiscalyearData.sort((a, b) => b.sorting_order - a.sorting_order)
    },
    formTitle () {
       return (this.editItemId === 0) ? this.$t('cotton_ginner_grower.manage_schedule_entry') : this.$t('cotton_ginner_grower.manage_schedule') + ' ' + this.$t('globalTrans.modify')
    },
    columns () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
          { label: this.$t('cotton_ginner_grower.growers_name'), class: 'text-left' },
          { label: this.$t('cotton_ginner_grower.region'), class: 'text-left' },
          { label: this.$t('org_pro_district.district'), class: 'text-left' },
          { label: this.$t('org_pro_upazilla.upazilla'), class: 'text-left' },
          { label: this.$t('cotton_ginner_grower.unit_name'), class: 'text-left' },
          { label: this.$t('cotton_ginner_grower.cottonVariety'), class: 'text-left' },
          { label: this.$t('cotton_ginner_grower.cottonName'), class: 'text-left' },
          { label: this.$t('cotton_ginner_grower.quantity'), class: 'text-left' },
          { label: this.$t('cotton_ginner_grower.total_quantity'), class: 'text-center' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'name_bn' },
          { key: 'region_name_bn' },
          { key: 'district_name_bn' },
          { key: 'upazila_name_bn' },
          { key: 'unit_name' },
          { key: 'cotton_variety_name_bn' },
          { key: 'cotton_name_bn' },
          { key: 'pro_achi_quantity' },
          { key: 'total_quantity' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'name' },
          { key: 'region_name' },
          { key: 'district_name' },
          { key: 'upazila_name' },
          { key: 'unit_name' },
          { key: 'cotton_variety_name' },
          { key: 'cotton_name' },
          { key: 'pro_achi_quantity' },
          { key: 'total_quantity' }
        ]
      }

      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
        })
    },
    isOfficeUser () {
    //   return !(this.$store.state.Auth.activeRoleId === 1 || this.$store.state.Auth.authUser.is_org_admin)
      return false
    }
  },
  watch: {
    loadingState: function (newVal, oldVal) {
      if (newVal) {
        this.loadData()
      }
    },
    'search.region_id': function (newVal, oldVal) {
        this.zoneList = this.getZoneList(newVal)
    },
    'search.zone_id': function (newVal, oldVal) {
        this.unitList = this.getUnitList(newVal)
    },
    'search.district_id': function (newVal, oldVal) {
        this.upazilaList = this.getUpazilaList(newVal)
    },
    'search.cotton_variety_id': function (newVal) {
        this.cottonNameList = this.getCottonNameList(newVal)
    },
    currentLocale: function (newVal, oldVal) {
        if (newVal) {
            this.zoneList = this.getZoneList(this.search.region_id)
            this.unitList = this.getUnitList(this.search.zone_id)
            this.upazilaList = this.getUpazilaList(this.search.district_id)
            this.cottonNameList = this.getCottonNameList(this.search.cotton_variety_id)
        }
    }
  },
  methods: {
    details (item) {
      this.editItemId = item.id
      this.item = item
    },
    searchData () {
      this.loadData()
      this.dataShow = true
    },
    async loadData () {
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      await RestApi.getData(agriMarketingServiceBaseUrl, CottonProductionReportList, this.search).then(response => {
        if (response.success) {
            this.$store.dispatch('setList', this.getRelatinalData(response))
        } else {
            this.cottonProductionList = []
            this.$store.dispatch('setList', [])
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
    },
    getRelatinalData (data) {
        let totalQuantity = 0
        const fiscalYearList = this.$store.state.commonObj.fiscalYearList
        const districtList = this.$store.state.commonObj.districtList
        const upazilaList = this.$store.state.commonObj.upazilaList
        const cottonVaritiesList = this.$store.state.agriMarketing.commonObj.cottonVaritiesList
        const cottonNameList = this.$store.state.agriMarketing.commonObj.cottonNameList
        const seasonList = this.$store.state.agriMarketing.commonObj.seasonList
        const regionList = this.$store.state.agriMarketing.commonObj.regionList
        const zoneList = this.$store.state.agriMarketing.commonObj.zoneList
        const unitList = this.$store.state.agriMarketing.commonObj.unitList

        this.totalRows = data.data.length
        const listData = data.data.map(item => {
        totalQuantity += item.pro_achi_quantity
        const fiscaleYearObject = fiscalYearList.find(fiscaleYear => fiscaleYear.value === item.fiscal_year_id)
        const districtObject = districtList.find(district => district.value === item.district_id)
        const upazilaObject = upazilaList.find(upazila => upazila.value === item.upazilla_id)
        const cottonVarityObject = cottonVaritiesList.find(data => data.value === item.cotton_variety_id)
        const cottonNameObject = cottonNameList.find(data => data.value === item.cotton_id)
        const seasonObject = seasonList.find(data => data.value === item.seasons_id)
        const regionObject = regionList.find(data => data.value === item.region_id)
        const zoneObject = zoneList.find(data => data.value === item.zone_id)
        const unitObject = unitList.find(data => data.value === item.unit_id)

        const fiscaleYearData = { fiscal_year_name: fiscaleYearObject?.text_en, fiscal_year_name_bn: fiscaleYearObject?.text_bn }
        const districtData = { district_name: districtObject !== undefined ? districtObject.text_en : '', district_name_bn: districtObject !== undefined ? districtObject.text_bn : '' }
        const upazilaData = { upazila_name: upazilaObject !== undefined ? upazilaObject.text_en : '', upazila_name_bn: upazilaObject !== undefined ? upazilaObject.text_bn : '' }
        const cottonVarityData = { cotton_variety_name: cottonVarityObject !== undefined ? cottonVarityObject.text_en : '', cotton_variety_name_bn: cottonVarityObject !== undefined ? cottonVarityObject.text_bn : '' }
        const cottonNameData = { cotton_name: districtObject !== undefined ? cottonNameObject.text_en : '', cotton_name_bn: cottonNameObject !== undefined ? cottonNameObject.text_bn : '' }
        const seasonData = { season_name: seasonObject !== undefined ? seasonObject.text_en : '', season_name_bn: seasonObject !== undefined ? seasonObject.text_bn : '' }
        const regionData = { region_name: regionObject !== undefined ? regionObject.text_en : '', region_name_bn: regionObject !== undefined ? regionObject.text_bn : '' }
        const zoneData = { zone_name: zoneObject !== undefined ? zoneObject.text_en : '', zone_name_bn: zoneObject !== undefined ? zoneObject.text_bn : '' }
        const unitData = { unit_name: unitObject !== undefined ? unitObject.text_en : '', unit_name_bn: unitObject !== undefined ? unitObject.text_bn : '' }

        return Object.assign({}, item, regionData, zoneData, unitData, fiscaleYearData, districtData, upazilaData, cottonVarityData, cottonNameData, seasonData)
      })
      this.totalQuantity = totalQuantity
        this.cottonProductionList = listData
        var serial = 0
        const rowData = data.data.map((keyItem, index) => {
        serial += 1
        if (this.$i18n.locale === 'en') {
            return {
                'sl ': serial,
                Region: this.cottonProductionList[index].region_name,
                District: this.cottonProductionList[index].district_name,
                Upazila: this.cottonProductionList[index].upazila_name,
                Unit: this.cottonProductionList[index].unit_name,
                'Cotton Variety': this.cottonProductionList[index].cotton_variety_name,
                'Cotton Name': this.cottonProductionList[index].cotton_name,
                'Quanty (Kg)': keyItem.pro_achi_quantity
                // 'Total Quanty (Kg)': this.totalQuantity
            }
        } else {
            return {
                'ক্রমিক সংখ্যা': this.$n(serial),
                অঞ্চল: this.cottonProductionList[index].region_name_bn,
                জেলা: this.cottonProductionList[index].district_name_bn,
                উপজেলা: this.cottonProductionList[index].upazila_name_bn,
                একক: this.cottonProductionList[index].unit_name_bn,
                'সুতার বিভিন্নতা': this.cottonProductionList[index].cotton_variety_name_bn,
                'সুতার নাম': this.cottonProductionList[index].cotton_name_bn,
                'পরিমাণ (কোজি)': this.$n(keyItem.pro_achi_quantity)
                // 'মোট পরিমাণ (কোজি)': this.$n(this.totalQuantity)
            }
        }
        })
        let footerRow
        if (this.currentLocale === 'en') {
            footerRow = {
                'sl ': '',
                Region: '',
                District: '',
                Upazila: '',
                Unit: '',
                'Cotton Variety': '',
                'Cotton Name': 'Total Quanty (Kg)',
                'Quanty (Kg)': this.$n(this.totalQuantity)
            }
        } else {
            footerRow = {
                'ক্রমিক সংখ্যা': '',
                অঞ্চল: '',
                জেলা: '',
                উপজেলা: '',
                একক: '',
                'সুতার বিভিন্নতা': '',
                'সুতার নাম': 'মোট পরিমাণ (কোজি)',
                'পরিমাণ (কোজি)': this.$n(this.totalQuantity)
            }
        }
        rowData.push(footerRow)
        this.excelData = rowData
    },
    getZoneList (regionId) {
        const zoneList = this.$store.state.agriMarketing.commonObj.zoneList.filter(item => item.region_id === regionId)
        return zoneList.map(item => {
            if (this.$i18n.locale === 'bn') {
                return { value: item.value, text: item.text_bn }
            } else {
                return { value: item.value, text: item.text_en }
            }
        })
    },
    getUnitList (zoneId) {
        const unitList = this.$store.state.agriMarketing.commonObj.unitList.filter(item => item.status === 1 && item.zone_id === zoneId)
        return unitList.map(item => {
            if (this.$i18n.locale === 'bn') {
                return { value: item.value, text: item.text_bn }
            } else {
                return { value: item.value, text: item.text_en }
            }
        })
    },
    getUpazilaList (districtId = null) {
        const upazilaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0)
        if (districtId) {
            return upazilaList.filter(upazila => upazila.district_id === districtId)
        }
        return upazilaList
    },
    getCottonNameList (cottonVarietyId = null) {
        let cottonNameList = this.$store.state.agriMarketing.commonObj.cottonNameList
        if (cottonVarietyId) {
            cottonNameList = cottonNameList.filter(item => item.cotton_variety_id === cottonVarietyId)
        }
        return cottonNameList
    },
    pdfExport () {
      const rowData = this.getPdfData()
      const labels = [
            { text: this.$t('globalTrans.sl_no'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
            { text: this.$t('cotton_ginner_grower.region'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
            { text: this.$t('org_pro_district.district'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
            { text: this.$t('org_pro_upazilla.upazilla'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
            { text: this.$t('cotton_ginner_grower.unit_name'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
            { text: this.$t('cotton_ginner_grower.cottonVariety'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
            { text: this.$t('cotton_ginner_grower.cottonName'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
            { text: this.$t('cotton_ginner_grower.quantity'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) }
            // { text: this.$t('cotton_ginner_grower.total_quantity'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) }
        ]
      const extraData = {
        totalHeaderRows: 1
      }
      const footerRow = [
        {},
        {},
        {},
        {},
        {},
        {},
        { text: this.$t('cotton_ginner_grower.total_quantity'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
        { text: this.$n(this.totalQuantity), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) }
      ]
      rowData.push(footerRow)
      rowData.unshift(labels)
      const reportTitle = this.$t('cotton_report.cotton_production_report')
      const columnWids = ['7%', '*', '*', '*', '*', '*', '*', '*']
      ExportPdf.exportPdf(agriMarketingServiceBaseUrl, '/cotton/config/report-heading/detail', this.search.org_id, reportTitle, rowData, columnWids, extraData, 'MOA', false)
    },
    getPdfData () {
        const keys = [
          { key: 'serial_no' },
          { key: this.$i18n.locale === 'en' ? 'region_name' : 'region_name_bn' },
          { key: this.$i18n.locale === 'en' ? 'district_name' : 'district_name_bn' },
          { key: this.$i18n.locale === 'en' ? 'upazila_name' : 'upazila_name_bn' },
          { key: this.$i18n.locale === 'en' ? 'unit_name' : 'unit_name_bn' },
          { key: this.$i18n.locale === 'en' ? 'cotton_variety_name' : 'cotton_variety_name_bn' },
          { key: this.$i18n.locale === 'en' ? 'cotton_name' : 'cotton_name_bn' },
          { key: 'pro_achi_quantity' }
        //   { key: 'total_quantity', rowSpan: this.totalRows }
        ]
      var serial = 0
      const listData = this.cottonProductionList.map(item => {
        serial += 1
        const rowData = keys.map((keyItem, index) => {
            if (keyItem.key === 'serial_no') {
                return { text: this.$n(serial) }
            }
            if (keyItem.key === 'pro_achi_quantity') {
                return { text: this.$n(item[keyItem.key]) }
            }
            // if (keyItem.key === 'total_quantity') {
            //     return { text: this.$n(this.totalQuantity) }
            // }

          return { text: item[keyItem.key] }
        })

        return rowData
      })

      return listData
    }
  }
}
</script>
